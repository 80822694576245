// import Vue from 'vue'
import {
  axios
} from '@/utils/request'

const api = {}
export default api
const headers = {
  'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
  'X-Requested-With': 'XMLHttpRequest'
}

export function postAction(url, parameter, headers) {
  const params = getParams(parameter)
  console.log('🚀 ~ file: manager.js ~ line 15 ~ postAction ~ params', params)
  return axios({
    url,
    method: 'post',
    data: parameter,
    headers
  })
}

export function httpAction(url, parameter, method) {
  // let sign =
  return axios({
    url,
    method,
    data: parameter,
    headers
  })
}

export function getAction(url, parameter) {
  // let sign =
  return axios({
    url,
    method: 'get',
    params: parameter,
    headers
  })
}

export function putAction(url, parameter) {
  // let sign =
  return axios({
    url,
    method: 'put',
    data: parameter,
  })
}

export function deleteAction(url, parameter) {
  return axios({
    url,
    method: 'delete',
    params: parameter
  })
}

function getParams(params) {
  let paramStr = ''
  Object.keys(params)
    .forEach((item) => {
      if (paramStr === '') {
        paramStr = `${item}=${params[item]}`
      } else {
        paramStr = `${paramStr}&${item}=${params[item]}`
      }
    })
  console.log(paramStr)
  return paramStr
}
