<template>
  <div class="header-area">
    <div class="logo-area">
      <router-link class="logo-inner" :to="{ path: '/welcome' }">
        <div class="logo">
          <img src="../assets/imgs/logo.png" alt="LOGO" />
        </div>
        <h2>君奕管理后台</h2>
      </router-link>
    </div>
    <div class="header-right">
      <div class="header-inner-left">
        <i
          @click="onChangeCollapsed"
          :class="collapsed ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
        ></i>
      </div>
      <div class="header-inner-right">
        <el-menu
          class="el-menu-demo"
          mode="horizontal"
          background-color="#23262E"
          text-color="#fff"
          active-text-color="#fff"
        >
          <el-submenu index="1">
            <template slot="title">设置</template>
            <el-menu-item index="1-1">修改密码</el-menu-item>
            <el-menu-item index="1-2">新建账号</el-menu-item>
          </el-submenu>
          <el-menu-item index="2" @click="onExit">退出登录</el-menu-item>
        </el-menu>
        <!-- <el-dropdown>
          <span class="el-dropdown-link">
            下拉菜单<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>修改密码</el-dropdown-item>
            <el-dropdown-item>新建账号</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  removeToken
} from '@/utils/auth'
import { MessageBox } from 'element-ui'
export default {
  methods: {
    onExit () {
      MessageBox.confirm('您将退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(res => {
        removeToken()
        window.location.reload()
      })
    },
    onChangeCollapsed () {
      this.collapsed = !this.collapsed
      this.$bus.$emit('changeCollapsed', this.collapsed)
    }
  },
  mounted () {
    this.$bus.$emit('changeCollapsed', this.collapsed)
  },
  data () {
    return {
      collapsed: false
    }
  },
}
</script>

<style lang="scss" scoped>
.header-area {
  display: flex;
}
.logo-area {
  flex: 0 0 180px;
}
.header-right {
  color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  .header-inner-left {
    font-size: 22px;
    cursor: pointer;
  }
  .header-inner-right {
    .el-menu-demo {
      background-color: transparent;
    }
    ::v-deep .el-submenu__title {
      border-bottom-color: transparent !important;
      color: red;
    }
    .el-menu-item {
      border-bottom-color: transparent !important;
    }
    .el-dropdown-link {
      color: #fff;
    }
  }
}

.logo-inner {
  display: flex;
  align-items: center;
  flex: 0 0 180px;
  height: 60px;
  .logo {
    color: #fff;
    height: 45px;
    width: 45px;
  }
  h2 {
    font-size: 18px;
    color: #fff;
    font-family: 'Chinese Quote', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue',
      Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol';
    font-weight: 700;
    margin-left: 8px;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
