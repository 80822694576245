import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
// const Home = () => import(/* webpackChunkName:'home' */ '../views/Home.vue')
const Merchant = () => import(/* webpackChunkName:'home' */ '../views/Merchant')
const Login = () => import(/* webpackChunkName:'login' */ '../views/Login')
const Order = () => import(/* webpackChunkName:'order' */ '../views/Order')
const Account = () => import(/* webpackChunkName:'order' */ '../views/Account')
// const Home = () => import(/* webpackChunkName:'order' */ '../views/Home.vue')
// const MerchantBranch = () => import(/* webpackChunkName:'order' */ '../views/MerchantBranch')

Vue.use(VueRouter)

export const constRoutes = [{
  path: '/',
  name: 'app',
  redirect: '/order',
  component: Layout,
  meta: {
    // title: '管理'
  },
  children: [
    // {
    //   path: '/home',
    //   name: 'Home',
    //   component: Home,
    //   meta: {
    //     hidden: false,
    //     title: '首页',
    //     icon: 'home'
    //   }
    // },
    {
      path: '/order',
      name: 'order',
      component: Order,
      meta: {
        hidden: false,
        title: '订单管理',
        icon: 'order'
      }
    },
    {
      path: '/merchant',
      name: 'merchant',
      component: Merchant,
      meta: {
        hidden: false,
        title: '商户管理',
        icon: 'merchant'
      }
    },
    {
      path: '/account',
      name: 'account',
      component: Account,
      meta: {
        hidden: false,
        title: '账号管理',
        icon: 'account'
      }
    },
  ]
},
{
  path: '/login',
  component: Login,
},
{
  path: '*',
  redirect: '/order'
}

]
export const asyncRoutes = [{
  path: '/merchant/branch',
  name: 'merchantBranch',
  component: () =>
    import('../views/MerchantBranch'),
  meta: {
    hidden: false,
    title: '商户分配',
    icon: 'branch',
    roles: ['admin']
  }
}]

const router = new VueRouter({
  mode: 'history',
  routes: constRoutes
})

export default router
