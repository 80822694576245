import {
  getToken,
  setToken,
  removeToken
} from '@/utils/auth'
import {
  login
} from '@/api/api'

const state = {
  token: getToken(),
  roles: []
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  }
}

const actions = {
  login({
    commit
  }, userInfo) {
    return new Promise((resolve, reject) => {
      login(userInfo).then(res => {
        if (res.code === 0) {
          commit('SET_TOKEN', userInfo.userName)
          setToken(userInfo.userName)
          resolve()
        } else {
          reject(new Error(res.msg))
        }
      })
    })
  },
  getInfo({
    commit,
    state
  }) {
    return new Promise(resolve => {
      setTimeout(() => {
        const roles = state.token === 'admin' ? ['admin'] : ['editor']
        commit('SET_ROLES', roles)
        resolve({
          roles
        })
      }, 1000)
    })
  },
  resetToken({
    commit
  }) {
    return new Promise((resolve) => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
