import store from './store'
import route from './router'
import {
  getToken
} from './utils/auth'
const whiteList = ['/login']
route.beforeEach(async (to, from, next) => {
  next()
  const hasToken = getToken()
  if (hasToken) {
    // 已经登录
    if (to.path === '/login') {
      next({
        path: '/',
        replace: true
      })
    } else {
      // 如果用户角色已附加说明动态路由已经添加
      const hasRoles = store.getters.roles && store.getters.roles.length > 0
      if (hasRoles) {
        next()
      } else {
        // 请求用户信息
        const {
          roles
        } = await store.dispatch('user/getInfo')
        // 根据当前用户生成动态路由
        const accessRoutes = await store.dispatch('permission/generateRoutes', roles)
        // 动态添加至路由器
        accessRoutes.forEach(item => {
          route.addRoute('app', item)
        })
        // 路由切换，确保addRouters完成
        next({
          ...to,
          replace: true
        })
      }
    }
  } else {
    // 未登录
    // 在白名单中
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      // 前往登录
      next(`/login?redirect=${to.path}`)
    }
  }
})
