<template>
  <el-container>
    <el-header>
      <Headerbar></Headerbar>
    </el-header>
    <el-container>
      <el-aside :class="collapsed ? 'active' : ''">
        <Sidebar @collapsedChange="onChangeCollapsed"></Sidebar>
      </el-aside>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Headerbar from './headerBar'
import Sidebar from './sideBar'
export default {
  components: { Headerbar, Sidebar },
  methods: {
    onChangeCollapsed (e) {
      this.collapsed = e
    }
  },
  data () {
    return {
      collapsed: false
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-container {
  height: calc(100% - 61px) !important;
}

::v-deep .el-aside {
  width: 200px !important;
  transition: all 0.3s linear;
  &.active {
    width: 66px !important;
  }
}
.el-header {
  background-color: #23262e;
}
.el-container {
  height: 100%;
}
.el-aside {
  background-color: #393d49;
  color: rgb(61, 59, 70);
}
</style>
