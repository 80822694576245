<template>
  <div>
    <!-- <el-scrollbar> -->
    <el-menu
      :default-active="activeIndex"
      :background-color="variables.menuBg"
      :text-color="variables.menuText"
      :unique-opened="false"
      :active-text-color="variables.menuActiveText"
      :collapse-transition="true"
      :collapse="collapsed"
      mode="vertical"
    >
      <SideMenuItem
        v-for="route in layout_routes"
        :key="route.path"
        :item="route"
        :base-path="route.path"
      ></SideMenuItem>
    </el-menu>

    <!-- </el-scrollbar> -->
  </div>
</template>

<script>
import SideMenuItem from './sideMenuItem'
import { mapGetters } from 'vuex'
export default {
  components: {
    SideMenuItem,
  },
  props: {
    collapsed: {
      type: Boolean,
      default: false
    },
  },
  mounted () {
    console.log('🚀 ~ file: index.vue ~ line 41 ~ mounted ~ this.layout_routes', this.layout_routes)
  },
  data () {
    this.onlyOneChild = null
    return {
    }
  },
  computed: {
    ...mapGetters(['layout_routes']),
    activeIndex () {
      const route = this.$route
      const { meta, path } = route
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    variables () {
      return {
        menuText: '#bfcbd9',
        menuActiveText: '#409eff',
        menuBg: '#304156',
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.el-menu {
  border-right: none;
}
</style>
