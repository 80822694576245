<template>
  <div>
    <SideMenu :collapsed="collapsed"></SideMenu>
  </div>
</template>

<script>
import SideMenu from '@/components/sideMenu'
export default {
  components: {
    SideMenu,
  },
  data () {
    return {
      collapsed: false
    }
  },
  mounted () {
    this.$bus.$on('changeCollapsed', this.onChangeCollapsed)
  },
  methods: {
    onChangeCollapsed (value) {
      this.collapsed = value
      this.$emit('collapsedChange', this.collapsed)
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
