import Vue from 'vue'
import '@/config'
import App from './App.vue'
import router from './router'
import store from './store'
import config from '@/defaultSettings'
import Storage from 'vue-ls'
import './premission'
import '@/element'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/reset.css'
import '@/icons'
import * as echarts from 'echarts'
import vueBus from '@/utils/vueBus'
if (customBrowserVersion().mobile) {
  window.location.href = 'http://shjunyi.vip/mobile'
}
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
Vue.use(vueBus)
Vue.use(Storage, config.storageOptions)
Vue.directive('loadmore', {
  bind(el, binding) {
    // 获取element-ui定义好的scroll盒子
    const SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap')
    SELECTWRAP_DOM.addEventListener('scroll', function () {
      const CONDITION = this.scrollHeight - this.scrollTop <= this.clientHeight
      if (CONDITION) {
        binding.value()
      }
    })
  }
})

Vue.filter('formateDate', function (date, format) {
  if (typeof date === 'string') {
    var mts = date.match(/(\/Date\((\d+)\)\/)/)
    if (mts && mts.length >= 3) {
      date = parseInt(mts[2])
    }
  }
  date = new Date(date)
  if (!date || date.toUTCString() === 'Invalid Date') {
    return ''
  }
  var map = {
    M: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    m: date.getMinutes(),
    s: date.getSeconds(),
    q: Math.floor((date.getMonth() + 3) / 3),
    S: date.getMilliseconds()
  }
  format = format.replace(/([yMdhmsqS])+/g, function (all, t) {
    var v = map[t]
    if (v !== undefined) {
      if (all.length > 1) {
        v = '0' + v
        v = v.substr(v.length - 2)
        return v
      }
    } else if (t === 'y') {
      return (date.getFullYear() + '').substr(4 - all.length)
    }
    return all
  })
  return format
})

function customBrowserVersion() {
  var u = navigator.userAgent
  return {
    trident: u.indexOf('Trident') > -1, // IE内核
    presto: u.indexOf('Presto') > -1, // opera内核
    webKit: u.indexOf('AppleWebKit') > -1, // 苹果、谷歌内核
    gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') === -1, // 火狐内核
    mobile: !!u.match(/AppleWebKit.*Mobile.*/), // 是否为移动终端
    ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
    android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1, // android终端
    iPhone: u.indexOf('iPhone') > -1, // 是否为iPhone或者QQHD浏览器
    iPad: u.indexOf('iPad') > -1, // 是否iPad
    webApp: u.indexOf('Safari') === -1, // 是否web应该程序，没有头部与底部
    weixin: u.indexOf('MicroMessenger') > -1, // 是否微信 （2015-01-22新增）
    qq: u.match(/\sQQ/i) === ' qq', // 是否QQ
    isBaidu: u.indexOf('baiduboxapp') !== -1, // 是否为百度浏览器
    isqqBrowser: u.indexOf('mqqbrowser') !== -1, // 是否为qq浏览器
    isWxBrowser: u.indexOf('micromessenger') !== -1, // 是否为微信浏览器
    isUc: u.indexOf('ucbrowser') !== -1, // 是否为uc浏览器
    ali: u.indexOf('AlipayClient') !== -1 // 是否为uc浏览器
  }
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
