import axios from 'axios'
// import Vue from 'vue'
import {
  VueAxios
} from './axios'
// import {
//   ACCESS_TOKEN
// } from '@/store/mutation-types'

// window._CONFIG.domainURL
const apiBaseUrl = window._CONFIG.domainURL

const service = axios.create({
  baseURL: apiBaseUrl,
  timeout: 15000
})

const err = error => {
  console.log(error)
}

service.interceptors.request.use(config => {
  // const token = Vue.ls.get(ACCESS_TOKEN)
  // if (token) {
  //   config.headers['X-Access-Token'] = token
  // }
  return config
})

service.interceptors.response.use(response => {
  return response.data
}, err)

const installer = {
  vm: {},
  install(Vue, router = {}) {
    Vue.use(VueAxios, router, service)
  }
}

export {
  installer as VueAxios,
  service as axios
}
