import {
  postAction,
  getAction
} from './manager'
const headers = {
  'Content-Type': 'application/json;charstet=UTF-8',
}

export const login = params => getAction('/bms/jypay/portLogin/selLogin', params)
export const getOrderList = params => getAction('/bms/jypay/bill/selBillData', params)
export const getMerchantList = params => getAction('/bms/jypay/customer/selCustomer', params)
export const getMerchantDetail = params => getAction('/bms/jypay/customer/selectById', params)
export const getAccountList = params => getAction('/bms/jypay/relation/admins', params)
export const getUserList = params => getAction('/bms/jypay/portLogin/findAllUser', params)
export const saveMerchant = params => getAction('/bms/jypay/customer/addCustomer', params)
export const updateMerchant = params => getAction('/bms/jypay/customer/updateCustomerData', params)
export const saveUser = params => getAction('/bms/jypay/portLogin/addUser', params)
export const updateUser = params => postAction('/bms/jypay/portLogin/updateUser', params, headers)
export const getMerchantRelationList = params => getAction('/bms/jypay/relation/list', params)
export const deleteMerchantBranch = params => getAction('/bms/jypay/relation/delete', params)
export const saveMerchantBranch = params => getAction('/bms/jypay/relation/assign', params)
export const getChildMerchantList = params => getAction('/bms/jypay/customer/selCustomerParentId', params)
export const getQrcodeImg = params => getAction('/bms/jypay/customer/create/qrcode', params)
export const getYearPerformance = params => getAction('/bms/jypay/stat/year/performance', params)
export const getRefundInitData = params => getAction('/bms/jypay/bill/refund/init', params)
export const saveRefundOrder = params => postAction('/bms/jypay/bill/refund', params)
export const getRefundList = params => getAction('/bms/jypay/bill/refund/list', params)
export const getAuthQrCode = params => getAction('/bms/jypay/customer/create/oauth', params)
const baseUrl = 'http://shjunyi.vip'
const payBaseUrl = 'http://shjunyi.vip/jyh5pay'
export {
  baseUrl,
  payBaseUrl
}
