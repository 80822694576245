import { render, staticRenderFns } from "./sideMenuItem.vue?vue&type=template&id=54e1d000&scoped=true&"
import script from "./sideMenuItem.vue?vue&type=script&lang=js&"
export * from "./sideMenuItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54e1d000",
  null
  
)

export default component.exports